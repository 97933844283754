.remodal {
  .remodal-close:before {
    color: #D9030E !important; } }


.steps-modal {
  width: auto;
  max-width: 95%;
  padding: 34px 35px 47px;
  background: #FFFFFF;
  box-shadow: 0 25px 50px rgba(130, 128, 141, 0.25);
  border-radius: 7px;

  .step-one {
    width: 532px;
    max-width: 100%; }

  .step-header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #191919;
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    .step-number {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      //font-weight: bold
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      min-width: 35px;
      height: 35px;
      min-height: 35px;
      border-radius: 50%;
      margin-right: 12px;
      background: linear-gradient(100.45deg, #FF545D -5.27%, #BF0912 107.93%);
      color: white; } }


  .locations {
    margin-bottom: 33px;

    .titles {
      padding-bottom: 15px;
      border-bottom: 1.2px solid rgba(25, 25, 25, 0.05);
      margin-bottom: 20px;

      .title {
        padding: 11px 52px 11px 48px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        //font-weight: normal
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #191919;
        border-radius: 30px;
        transition: all .1s ease-out;
        margin-right: 12px;
        cursor: pointer;

        &.current {
          background: rgba(234, 59, 68, 0.12); }

        &:hover {
          background: rgba(234, 59, 68, 0.12);
          opacity: .7; } } }


    .location {
      width: 100%;
      text-align: left;

      &:not(.current) {
        display: none; }

      .description {
        padding-top: 20px;
        padding-left: 47px;
        margin-bottom: 4px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        //font-weight: normal
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #191919; }

      .address {
        padding-left: 47px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 135%;
        color: #7F7F81;
        margin-bottom: 17px; }

      .map {
        height: 137px;
        overflow: hidden; } } }

  .next-step {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 17px 61px 15px 60px;
    background: linear-gradient(100.45deg, #FF545D -5.27%, #BF0912 107.93%);
    border-radius: 7px;
    transition: all .1s ease-out;
    position: relative;

    &.has-next:after {
      content: '';
      position: absolute;
      right: 17px;
      top: 20px;
      display: inline-block;
      width: 14px;
      height: 13px;
      background: url('../img/next-button.svg');
      background-size: 100% 100%; }

    &:hover {
      opacity: .7; }

    &:disabled {
      background: #ababab;
      color: #191919; } }


  .flatpickr-calendar.inline {
    //margin: 0 auto 70px
    margin: 0 auto 30px;
    width: 293px;
    box-shadow: none !important;

    .numInputWrapper {
      .arrowUp,
      .arrowDown {
        display: none; }
      &:hover {
        background: transparent !important;
        cursor: default !important; }
      .cur-year {
        user-select: none !important;
        cursor: default !important;
        &::selection, &::-moz-selection {
          background: white !important; } } }

    .flatpickr-months {
      display: flex;
      justify-content: center;

      .flatpickr-prev-month svg,
      .flatpickr-next-month svg {
        fill: #D9030E; }

      .flatpickr-current-month .flatpickr-monthDropdown-months,
      .flatpickr-current-month .numInputWrapper input.cur-year {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: #191919;
        background: transparent !important;
        //border: none !important
        border: 3px solid white !important;
        outline: none !important; }

      .flatpickr-current-month .numInputWrapper input.cur-year {
        width: 55px;
        padding: 0; }

      //.flatpickr-prev-month,
      //.flatpickr-next-month
      ////position: static

      .flatpickr-current-month {
        width: auto;
        position: static;
        font-size: 100%;
        padding: 0;
        display: inline-flex;
        align-items: center; }

      .flatpickr-month {
        width: 100%;
        //max-width: calc(100% - 40px)
        display: inline-block;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: #191919;
        margin-bottom: 10px;
        //width: auto
        flex-basis: 0;
        flex-grow: inherit;
        flex-shrink: unset;
        //min-width: 60%
        min-width: 100%; }

      .flatpickr-prev-month,
      .flatpickr-next-month {
        padding: 3px 14px; }

      .flatpickr-prev-month.flatpickr-disabled,
      .flatpickr-next-month.flatpickr-disabled {
        display: block;
        opacity: .4; } }

    .flatpickr-weekdays {
      .flatpickr-weekdaycontainer {
        width: 293px;
        min-width: 293px;
        max-width: 293px;
        justify-content: space-between; }

      .flatpickr-weekday {
        box-sizing: border-box;
        margin: 0 4px;
        max-width: 32px; } }

    .dayContainer {
      width: 293px;
      min-width: 293px;
      max-width: 293px;

      .flatpickr-day {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        height: 32px;
        width: 32px;
        min-height: 32px;
        min-width: 32px;
        max-height: 32px;
        max-width: 32px;
        line-height: 1;
        margin: 0 4px 8px;
        flex-basis: 14.2%;

        &.nextMonthDay {
          &:not(.flatpickr-disabled) {
            &:not(.inRange) {
              &:not(.selected) {
                &:not(.red) {
                  color: #515155; } } } } }

        &.inRange,
        &.flatpickr-day.flatpickr-disabled.inRange:last-child + .flatpickr-day.flatpickr-disabled {
          background: #F7CDCF;
          border: 0;
          border-radius: 0;
          position: relative;
          color: #515155;
          box-shadow: none;

          &:before {
            content: '';
            z-index: -1;
            display: block;
            background: #F7CDCF;
            width: 93px;
            height: 32px;
            position: absolute; } }

        &.selected.startRange,
        &.selected.endRange {
          color: white;
          background: #D9030E;
          border-radius: 50%;

          //&:before
 }          //  display: none

        &.selected,
        &.startRange,
        &.endRange,
        &.selected.inRange,
        &.startRange.inRange,
        &.endRange.inRange,
        &.selected:focus,
        &.startRange:focus,
        &.endRange:focus,
        &.selected:hover,
        &.startRange:hover,
        &.endRange:hover,
        &.selected.prevMonthDay,
        &.startRange.prevMonthDay,
        &.endRange.prevMonthDay,
        &.selected.nextMonthDay,
        &.startRange.nextMonthDay,
        &.endRange.nextMonthDay {
          background: #D9030E;
          color: white; }

        &.selected.startRange + .endRange:not(:nth-child(7n+1)),
        &.startRange.startRange + .endRange:not(:nth-child(7n+1)),
        &.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
          box-shadow: none; }

        &.flatpickr-disabled {
          color: #aeaeae;

          &:hover {
            background: #ffd5d5; } }

        &.nextMonthDay {
          &:hover,
          &.startRange,
          &.endRange {
            color: white !important; } }

        &.startRange + .endRange:before {
          content: '';
          z-index: -1;
          display: block;
          background: #F7CDCF;
          height: 32px;
          position: absolute;
          width: 50px;
          left: -100%; }

        &.red,
        &.red:hover {
          &.flatpickr-disabled {
            opacity: 1 !important; }

          &:not(.startRange) {
            &:not(.endRange) {
              color: #D9030E; } }

          &.selected {
            color: white; } } } } }


  .timepickers {
    width: 281px !important;
    margin-left: 79px;
    padding-top: 9px;

    .datarange-error {
      text-align: left;
      color: #D9030E; }

    .timepicker-header {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #191919;
      margin-bottom: 18px;
      text-align: left; }


    .timepicker-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 46px;

      .before,
      .after {
        display: inline-block;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 120%;
        text-align: center;
        color: #515155;
        visibility: visible !important;
        background: white;
        margin-bottom: 2px;
        width: 62px; }

      .before {
        padding-right: 15px; }

      .after {
        padding-left: 15px; } }

    .irc-wrapper {
      display: inline-block;
      width: 100%;

      .irs {
        .irs-handle {
          background: #D9030E;
          box-shadow: 0 4px 12px rgba(217, 3, 14, 0.12);
          border-radius: 50%;
          width: 22px;
          min-width: 22px;
          height: 22px;
          min-height: 22px;
          border: none;

          i {
            display: none; } }

        .irs-line,
        .irs-bar {
          height: 2px;
          background: #F7CDCF; }

        .irs-single {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          //font-weight: normal
          font-weight: 500;
          font-size: 18px;
          line-height: 120%;
          text-align: center;
          color: #474545;
          background: transparent;
          top: -9px;

          &:before {
            display: none; } }

        .irs-min,
        .irs-max {
          display: none; } } }

    .timepickers-description {
      padding-top: 15px;

      p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #515155;
        opacity: 0.5;
        margin-bottom: 14px;
        text-align: left; } } } }


.daterangepicker {
  z-index: 10000 !important; }

@media screen and (max-width: 1199px) {
  .steps-modal {
    .calendar,
    .timepickers {
      width: 100% !important;
      margin-left: 0; } } }

@media screen and (max-width: 575px) {
  .steps-modal {
    padding: 34px 15px 47px;

    .locations {
      .location {
        .description,
        .address {
          padding-left: 0; } }

      .titles {
        justify-content: center;

        .title {
          margin-right: 0;
          padding: 8px 20px; } } } } }
